import React, { useContext, useEffect, useState } from "react"
import { isEmpty } from "lodash"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { getCookie, getFormattedCart, getOgImage } from "../../utils/functions"
import { AppContext } from "../../components/context/AppContext"

import "./style.scss"
import ProductsList from "../../components/productsList/ProductsList"
import OrderStepIndyficator from "../../components/orderStepIndyficator/OrderStepIndyficator"
import { useLazyQuery } from "@apollo/client"
import GET_CART from "../../queries/get-cart"
import CheckoutProducts from "../../components/checkout/products/Products"
import { Link, withPrefix } from "gatsby"
import { Helmet } from "react-helmet"
import CheckoutCoupons from "../../components/checkout/coupons/Coupons"

const ThankYouPage = props => {
  const {
    pageContext: { title, seo, uri },
    location,
  } = props

  const {
    title: contentTitle,
    description,
    tableTextOrderDate,
    tableTextOrderTotal,
    tableTextOrderNumber,
    tableTextOrderPayment,
    tableTextOrderProducts,
    tableTextOrderCoupons,
    tableTextOrderShipping,
    tableTextOrderVatIncluded,
    moreProductHeading,
    products,
    productButtonText,
    showAllProductLink,
  } = props.pageContext.acfThankyou

  // const { e } = props;

  const { code: locale } = props.pageContext.wpmlTranslated.current[0]
  const { node: translated } = props.pageContext.wpmlTranslated
  const lang = locale.substring(0, 2)
  const { orderData, setCart } = useContext(AppContext)
  const [productList, setProductList] = useState([])
  const [tmpOrderData, setOrderData] = useState({})
  const [orderPath, setOrderPath] = useState([])

  const [getCart, {}] = useLazyQuery(GET_CART, {
    variables: {
      country: lang.toUpperCase(),
    },
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    nextFetchPolicy: "network-only",
    onCompleted: data => {
      // console.warn( 'completed GET_CART' );
      // Update cart in the localStorage.
      const updatedCart = getFormattedCart(data)
      // localStorage.setItem("woo-next-cart", JSON.stringify(updatedCart))
      setCart(updatedCart)

      // localStorage.removeItem("woo-session");
      // localStorage.removeItem("woo-next-cart");
      // localStorage.removeItem("woo-order");
    },
  })

  const today = new Date()
  const yyyy = today.getFullYear()
  let mm = today.getMonth() + 1 // Months start at 0!
  let dd = today.getDate()

  if (dd < 10) dd = "0" + dd
  if (mm < 10) mm = "0" + mm

  let currentToday = dd + "." + mm + "." + yyyy
  useEffect(() => {
    if (lang === "pl") {
      setOrderPath(props.pageContext.orderPathPL)
    }
    if (lang === "en") {
      setOrderPath(props.pageContext.orderPathEN)
    }
    if (lang === "de") {
      setOrderPath(props.pageContext.orderPathDE)
    }
    if (lang === "fr") {
      setOrderPath(props.pageContext.orderPathFR)
    }

    let tmp = []
    products.forEach(element => {
      tmp.push({ ...element.product })
    })
    setProductList(tmp)
    getCart()
    if (isEmpty(orderData)) {
      let orderDataLocalStorage = localStorage.getItem("woo-order")
      orderDataLocalStorage =
        null !== orderDataLocalStorage ? JSON.parse(orderDataLocalStorage) : {}
      setOrderData(orderDataLocalStorage)
    } else {
      setOrderData(orderData)
    }
    return () => {
      // localStorage.removeItem("woo-session");
      // localStorage.removeItem("woo-next-cart");
      // localStorage.removeItem("woo-order");
    }
  }, [])

  return (
    <Layout lang={lang} page="thankYou" translated={translated}>
      {!isEmpty(props.pageContext) ? (
        <>
          <SEO
            title={title}
            translated={translated}
            seoData={seo}
            uri={uri}
            lang={lang}
            page={"thankYou"}
            // header={ siteTitle: 'Gatsby WooCommerce Theme' }}
            openGraphImage={getOgImage(seo)}
          />
          {getCookie("gatsby-gdpr") && tmpOrderData ? (
            <Helmet>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
                src={`https://t.goadservices.com/engine/${
                  process.env.goPl
                }?id=${getCookie("__goadservices")}`}
              ></script>
              <script
                type="text/javascript"
                ata-rh="true"
                data-react-helmet="true"
              >
                {`
                  var goadservicesq = goadservicesq || [];
                  try {
                    goadservicesq.push(
                        [
                            "_ORDER",
                            {
                              identifier: '${
                                tmpOrderData.cartData
                                  ?.totalProductsWithoutCoupnFloat
                              }',
                              amount: '${
                                tmpOrderData.checkoutData?.checkout.order
                                  .orderNumber
                              }',
                            },
                            ${tmpOrderData.cartData?.products.map(item => {
                              return `
                              {
                                identifier: '${item.productId}',
                                quantity: '${item.qty}',
                              },
                              `
                            })}
                        ]
                    );
                  } catch (e) {
                      // console.log(e)
                  }
                `}
              </script>

              <script type="text/javascript">
                {`
                setTimeout(()=>{
                  const dataLayer = window.dataLayer || [];
                  // window.dataLayer = window.dataLayer || [];
                  dataLayer.push({
                  'transactionId': '${
                    tmpOrderData.checkoutData?.checkout.order.orderNumber
                  }',
                  'transactionTotal': ${parseFloat(
                    tmpOrderData.cartData?.totalPriceWithShippingAndCouponFloat
                  )},
                    'transactionProducts': [
                      ${tmpOrderData.cartData?.products.map(item => {
                        return `
                        {
                          name: '${item.name}',
                          category: '${item.category}',
                          price: ${item.total},
                          quantity: ${item.qty},
                        },
                        `
                      })}
                    ]
                  });
                }, 500);
            `}
              </script>
            </Helmet>
          ) : (
            ""
          )}

          {!isEmpty(tmpOrderData) ? (
            <div className="thankYou">
              <OrderStepIndyficator
                orderPath={orderPath.stepIndyficator}
                activeStep={3}
              />
              <div className="container">
                <section className="thankYou__data">
                  <div className="thankYou__wrapper">
                    <h1 className="heading--xl">{contentTitle}</h1>
                    <div className="content">
                      <div dangerouslySetInnerHTML={{ __html: description }} />

                      <div className="thankYou__table">
                        <div className="thankYou__table__row">
                          <div className="thankYou__table__col">
                            {tableTextOrderNumber}
                          </div>
                          <div className="thankYou__table__col">
                            <strong>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html:
                                    tmpOrderData?.checkoutData?.checkout.order
                                      .orderNumber,
                                }}
                              />
                            </strong>
                          </div>
                        </div>

                        <div className="thankYou__table__row">
                          <div className="thankYou__table__col">
                            {tableTextOrderDate}
                          </div>
                          <div className="thankYou__table__col">
                            <strong>{currentToday}</strong>
                          </div>
                        </div>

                        {tmpOrderData?.withPickup ? (
                          ""
                        ) : (
                          <div className="thankYou__table__row">
                            <div className="thankYou__table__col">
                              {tableTextOrderPayment}
                            </div>
                            <div className="thankYou__table__col">
                              <strong>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      tmpOrderData?.checkoutData?.checkout.order
                                        .paymentMethodTitle,
                                  }}
                                />
                              </strong>
                            </div>
                          </div>
                        )}

                        {tmpOrderData?.checkoutData?.checkout.order
                          .shippingLines?.nodes.length ? (
                          <div className="thankYou__table__row">
                            <div className="thankYou__table__col">
                              {tableTextOrderShipping}
                            </div>
                            <div className="thankYou__table__col">
                              <strong>
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      tmpOrderData?.checkoutData?.checkout.order
                                        .shippingLines?.nodes[0].methodTitle,
                                  }}
                                />
                              </strong>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="thankYou__table__row">
                          <div className="thankYou__table__col">
                            {tableTextOrderProducts}
                          </div>
                          <div className="thankYou__table__col"></div>
                          <div className="row">
                            <CheckoutProducts
                              products={tmpOrderData?.cartData.products}
                            />
                          </div>
                        </div>

                        {!isEmpty(tmpOrderData?.cartData.coupons) ? (
                          <div className="thankYou__table__row">
                            <div className="thankYou__table__col">
                              {tableTextOrderCoupons}
                            </div>
                            <div className="thankYou__table__col"></div>
                            <div className="row">
                              <CheckoutCoupons
                                coupons={tmpOrderData?.cartData.coupons}
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {tmpOrderData?.cartData.subscription ? (
                          tmpOrderData?.cartData
                            .totalProductsWithoutCoupnFloat ? (
                            <>
                              <div className="thankYou__table__row thankYou__table__row__total">
                                <div className="thankYou__table__col">
                                  {tableTextOrderTotal}
                                </div>
                                <div className="thankYou__table__col ">
                                  <strong className="thankYou__table__row__price">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          tmpOrderData?.cartData
                                            .totalPriceWithCoupon,
                                      }}
                                    ></span>
                                    <span className="thankYou__table__row__vat">
                                      {tableTextOrderVatIncluded}
                                    </span>
                                  </strong>
                                </div>
                              </div>
                              <div className="thankYou__table__row thankYou__table__row__total">
                                <div className="thankYou__table__col">
                                  {tableTextOrderTotal}
                                </div>
                                <div className="thankYou__table__col ">
                                  <strong className="thankYou__table__row__price">
                                    <span
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          tmpOrderData?.cartData
                                            .subscriptionData
                                            .totalPriceWithShipping,
                                      }}
                                    ></span>
                                    <span className="thankYou__table__row__vat">
                                      {tableTextOrderVatIncluded}
                                    </span>
                                  </strong>
                                </div>
                              </div>
                            </>
                          ) : (
                            <div className="thankYou__table__row thankYou__table__row__total">
                              <div className="thankYou__table__col">
                                {tableTextOrderTotal}
                              </div>
                              <div className="thankYou__table__col ">
                                <strong className="thankYou__table__row__price">
                                  <span
                                    dangerouslySetInnerHTML={{
                                      __html:
                                        tmpOrderData?.cartData.subscriptionData
                                          .totalPriceWithShipping,
                                    }}
                                  ></span>
                                  <span className="thankYou__table__row__vat">
                                    {tableTextOrderVatIncluded}
                                  </span>
                                </strong>
                              </div>
                            </div>
                          )
                        ) : (
                          <div className="thankYou__table__row thankYou__table__row__total">
                            <div className="thankYou__table__col">
                              {tableTextOrderTotal}
                            </div>
                            <div className="thankYou__table__col ">
                              <strong className="thankYou__table__row__price">
                                <span
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      tmpOrderData?.cartData
                                        .totalPriceWithShippingAndCoupon,
                                  }}
                                ></span>
                                <span className="thankYou__table__row__vat">
                                  {tableTextOrderVatIncluded}
                                </span>
                              </strong>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </section>
                <div
                  className="thankYou__products"
                  style={{
                    backgroundImage: `url(${withPrefix(
                      "/images/product_cat_bg.png"
                    )})`,
                    backgroundPosition: "left top",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                  }}
                >
                  <div className="thankYou__products__info">
                    <h2 className="thankYou__products__heading heading--big heading--center heading--dash">
                      {moreProductHeading}
                    </h2>
                    <Link
                      className="thankYou__products__link"
                      to={showAllProductLink.url}
                    >
                      {showAllProductLink.title}
                    </Link>
                  </div>
                  <ProductsList
                    action="link"
                    productBoxButton={productButtonText}
                    products={productList}
                    columns={4}
                  />
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <div>Something went wrong</div>
      )}
    </Layout>
  )
}
export default ThankYouPage
